import '../../styles/admin_portal/admin_pending_page_user_card.css'

function PendingPageUserCard({user}){
    return(
        <div className={'pending_user_info_card'}>
            <h1>{user.first_name}  {user.last_name}</h1>
            <p>隶属部门: {user.department}</p>
            <p>注册代码: {user.regkey}</p>
            <p></p>
        </div>
    )
}

export default PendingPageUserCard;