import '../../Constants/api'
import LoginCard from "../../HelperComponent/LoginCard";

import React, {useEffect, useState} from "react";
import axios from "axios";
import {user_home} from "../../Constants/api";

function UserHome() {
    const [authorized, setAuthorized] = useState(false);

    useEffect(() => {
        const checkAuthorization = async () => {
            const token = localStorage.getItem('jwtToken');
            if (!token) {
                setAuthorized(false);
                return;
            }
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            try {
                const response = await axios.post(user_home, {}, config)
                if (response.status === 200) {
                    setAuthorized(true);
                }
            } catch (error) {
                setAuthorized(false);
                console.error('Error accessing protected route:', error);
            }
        }
        checkAuthorization();
    });


    return (
        <div>
            {authorized ? (
                <div>
                    <h1>User Home Page</h1>
                </div>
            ) : (
                <div className='card-container'>
                    <LoginCard
                        title={"您无权访问该网页"}
                        sub_title={"此界面仅可被Meris内部员工访问。如果您是Meris用户，请尝试重新登录您的Meris账号，然后重新访问此页。如果您认为您有权限访问但仍持续看到此见面，请寻求Meris IT以寻求技术支持。"}
                        render_text_button={0}
                        render_text_box={false}
                        render_filled_button={false}
                    ></LoginCard>
                </div>
            )}
        </div>
    )
}

export default UserHome;