import '../../styles/admin_portal/admin_portal_card.css'
function RightCard() {
    return (
        <div className={'right-card'}>
            <h1>
                this is the right side card
            </h1>
        </div>
    )
}

export default RightCard;