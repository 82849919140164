import {useEffect, useState} from "react";
import '../../Constants/api'
import {admin_list_pending_user} from "../../Constants/api";
import axios from "axios";
import PendingPageUserCard from "../../HelperComponent/Portal/PendingPageUserCard";

function AdminPendingPage() {
    const [users, setUser] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        const fetchData = async () => {
            try {
                const response = await axios.post(admin_list_pending_user, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setUser(response.data.data.documents);
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [])


    return (
        <div className={'pending_user_info_card_container'}>
            {users.map(user => (
                <PendingPageUserCard user={user}></PendingPageUserCard>  // Using _id as key for performance reasons
            ))}
        </div>
    );
}

export default AdminPendingPage;