import LoginCard from '../../HelperComponent/LoginCard'
import '../../styles/login_page_styles/LoginDisplay.css'
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import '../../styles/login_page_styles/HelpfulLinks.css'
import {user_login, user_username_lookup} from "../../Constants/api";
import axios from "axios";

function LoginDisplay() {
    const [userName, setUsername] = useState('');
    const [step, setStep] = useState('username');
    const [password, setPassword] = useState('');
    const [loadAnimation, setLoadAnimation] = useState(false);
    const [isError, setIsError] = useState(false);
    const [name, setName] = useState('');


    const navigate = useNavigate();

    const passwordInput = (event) => {
        setPassword(event.target.value);
    }

    const usernameInput = (event) => {
        setUsername(event.target.value);
    };
    const signinClick = async (event) => {
        setLoadAnimation(true);
        event.preventDefault();

        const apiUrl = user_username_lookup
        const requestData = {
            username: userName
        }

        try {
            const response = await axios.post(apiUrl, requestData);
            if (response.status === 200) {
                setIsError(false);
                setLoadAnimation(false);
                setStep('password');
                setName(response.data.name);
            }
        } catch (error) {
            setIsError(true);
            setLoadAnimation(false);
        }
    }

    const signInClickPassword = async (event) => {
        setLoadAnimation(true);
        event.preventDefault();

        const apiUrl = user_login
        const requestData = {
            username: userName,
            password:  password
        }

        try{
            const response = await axios.post(apiUrl, requestData);
            if (response.status === 200) {
                localStorage.setItem('jwtToken', response.data.token);
                console.log(localStorage.getItem('jwtToken'));
                setIsError(false);
                setLoadAnimation(false);
                navigate('../user/home');
            }
        }
        catch (error) {
            setIsError(true);
            setLoadAnimation(false);
        }

    }

    const signupClick = (event) => {
        navigate('/signup');
    }

    const adminLoginPageButton = (event) => {
        navigate('./admin/login')
    }

    return (
        <div>
            {step === 'username' && (
                <div className='card-container'>
                    <LoginCard
                        textInput={userName}
                        setTextInput={usernameInput}
                        filled_button_click={signinClick}
                        text_button_click={signupClick}
                        title={"登录"}
                        sub_title={"使用您的Meris账号"}
                        text_holder={"电子邮箱地址或电话号码"}
                        text_button={"创建账号"}
                        filled_button={"下一步"}
                        loadAnimation={loadAnimation}
                        isError={isError}
                        error_message={"该用户不存在"}
                    ></LoginCard>
                    <div className='helpful_links_container'>
                        <button className='admin_login_page_button' onClick={adminLoginPageButton}>管理员登录</button>
                        <p className='helpful_links'>帮助 隐私权 条款</p>
                    </div>
                </div>
            )}
            {step === 'password' && (
                <div className='card-container'>
                    <LoginCard
                        textInput={password}
                        setTextInput={passwordInput}
                        filled_button_click={signInClickPassword}
                        title={name}
                        sub_title={"请输入您的密码以继续"}
                        text_holder={"密码"}
                        render_text_button={0}
                        filled_button={"登录"}
                        loadAnimation={loadAnimation}
                        isError={isError}
                        error_message={"密码错误"}
                        inputType1={'password'}
                    ></LoginCard>
                    <div className='helpful_links_container'>
                        <button className='admin_login_page_button' onClick={adminLoginPageButton}>管理员登录</button>
                        <p className='helpful_links'>帮助 隐私权 条款</p>
                    </div>
                </div>
            )}
        </div>

    );
}

export default LoginDisplay;