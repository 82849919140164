import {useState} from "react";
import LoginCard from "../../HelperComponent/LoginCard";
import {useNavigate} from "react-router-dom";
import {user_check_regkey, user_register} from "../../Constants/api";
import axios from "axios";

function SignupDisplay() {

    const [regkey, setRegkey] = useState('');
    const [step, setStep] = useState('regCode');
    const [loadingAnimation, setLoadingAnimation] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
    const [passwordError, setPasswordError] = useState(false);

    const regkeyInput = (event) => {
        setRegkey(event.target.value);
    };

    const passwordInput = (event) => {
        setPassword(event.target.value);
    }

    const confirmPasswordInput = (event) => {
        setConfirmPassword(event.target.value);
    }

    const goto_login_page = (event) => {
        navigate('/');
    }

    const signupClick = async (event) => {
        setLoadingAnimation(true);
        event.preventDefault();

        const apiUrl = user_check_regkey;
        const requestData = {
            regkey: regkey,
        }

        try {
            const res = await axios.post(apiUrl, requestData);
            setFirstName(res.data.data.first_name);
            setLastName(res.data.data.last_name);
            setStep('setPassword');
            setLoadingAnimation(false);

        } catch (error) {
            setLoadingAnimation(false);
            setIsError(true);
        }
    }

    const passwordClick = async (event) => {
        event.preventDefault();
        if (password.length < 8) {
            setPasswordError(true);
            setPasswordErrorMsg('密码长度不得少于8个字符')
        } else if (password !== confirmPassword) {
            setPasswordError(true);
            setPasswordErrorMsg('这两个密码不一致，请重试。')
        } else {
            setLoadingAnimation(true)
            const apiUrl = user_register
            const requestData = {
                regkey: regkey,
                password: password,
            }
            try {
                await axios.post(apiUrl, requestData);
                setStep('registerSuccess')
                setLoadingAnimation(false);
            } catch (error) {
                setLoadingAnimation(false);
                console.log(error);
            }
        }
    }

    const backClick = (event) => {
        navigate('/');
    }

    return (
        <div>
            {step === 'regCode' && (
                <div className='card-container'>
                    <LoginCard
                        textInput={regkey}
                        setTextInput={regkeyInput}
                        text_button_click={backClick}
                        filled_button_click={signupClick}
                        title={"注册Meris账号"}
                        sub_title={"请输入IT提供给您的账号注册代码"}
                        text_holder={"注册代码"}
                        text_button={"返回"}
                        filled_button={"下一步"}
                        loadAnimation={loadingAnimation}
                        isError={isError}
                        error_message={"该注册码无效"}
                    ></LoginCard>
                    <p className='text-right'>帮助 隐私权 条款</p>
                </div>
            )}
            {step === 'setPassword' && (
                <div className='card-container'>
                    <LoginCard
                        textInput={password}
                        setTextInput={passwordInput}
                        text_input2={confirmPassword}
                        setTextInput2={confirmPasswordInput}
                        filled_button_click={passwordClick}
                        title={firstName + ' ' + lastName}
                        sub_title={<span>欢迎加入Meris。<br/>请为您的Meris账号设置一个密码</span>}
                        text_holder={"密码"}
                        filled_button={"下一步"}
                        loadAnimation={loadingAnimation}
                        isError={passwordError}
                        error_message={passwordErrorMsg}
                        render_second_textInput={true}
                        text_holder2={"确认密码"}
                        render_text_button={0}
                        inputType1={'password'}
                        inputType2={'password'}
                    ></LoginCard>
                    <p className='text-right'>帮助 隐私权 条款</p>
                </div>
            )}
            {step === 'registerSuccess' && (
                <div className='card-container'>
                    <LoginCard
                        title={'注册成功！'}
                        sub_title={'您已成功激活您的Meris账号，您现在可以前往登录界面已登录。'}
                        loadAnimation={loadingAnimation}
                        render_text_box={false}
                        render_filled_button={true}
                        filled_button={"登录页"}
                        filled_button_click={goto_login_page}
                        render_second_textInput={false}
                        render_text_button={0}
                    ></LoginCard>
                    <p className='text-right'>帮助 隐私权 条款</p>
                </div>
            )}
        </div>
    )
}

export default SignupDisplay;