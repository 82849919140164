import LoginCard from '../../HelperComponent/LoginCard'
import '../../styles/login_page_styles/LoginDisplay.css'
import { useState } from 'react';
import axios from 'axios';
import { admin_user_name_lookup, admin_user_password_check } from '../../Constants/api'
import { useNavigate } from 'react-router-dom';
import '../../styles/login_page_styles/HelpfulLinks.css'

function AdminLogin() {
    const [userName, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [step, setStep] = useState('username');
    const [isError, setIsError] = useState(false);
    const [loadAnimation, setLoadAnimation] = useState(false);
    const navigate = useNavigate();

    const usernameInput = (event) => {
        setUsername(event.target.value);
    };

    const passwordInput = (event) => {
        setPassword(event.target.value);
    }


    const signinClick_username = async (event) => {
        setLoadAnimation(true);
        event.preventDefault();

        const apiUrl = admin_user_name_lookup;
        const requestData = {
            username: userName
        };

        try {
            const response = await axios.post(apiUrl, requestData);
            if (response.data.success) {
                setIsError(false);
                setLoadAnimation(false);
                setStep('password');
            }
            if (response.data.error) {
                setLoadAnimation(false);
                setIsError(true);
            }
        } catch (error) {
            setLoadAnimation(false);
        }
    };

    const signin_click_password = async (event) => {
        setLoadAnimation(true);
        event.preventDefault();

        const apiUrl = admin_user_password_check;
        const requestData = {
            username: userName,
            password: password
        }

        try {
            const res = await axios.post(apiUrl, requestData);
            if (res.data.success) {
                setIsError(false);
                setLoadAnimation(false);
                localStorage.setItem('jwtToken', res.data.token);

                navigate('../admin/portal');
            }
            if (res.data.failed) {
                setLoadAnimation(false);
                setIsError(true);
            }
        } catch (error) {
            console.log('Error received:', error);
        }


    }

    const userLoginPageButton = (event) => {
        navigate('/')
    }

    return (
        <div className='card-container'>
            {step === 'username' && (
                <LoginCard
                    textInput={userName}
                    setTextInput={usernameInput}
                    filled_button_click={signinClick_username}
                    title={"管理员登录"}
                    sub_title={"使用您的Meris管理员账号"}
                    text_holder={"电子邮箱地址或电话号码"}
                    filled_button={"下一步"}
                    render_text_button={0}
                    isError={isError}
                    error_message={" 找不到您的Meris管理员账号"}
                    loadAnimation={loadAnimation}
                ></LoginCard>
            )}
            {step === 'password' && (
                <LoginCard
                    textInput={password}
                    setTextInput={passwordInput}
                    filled_button_click={signin_click_password}
                    title={"管理员登录"}
                    sub_title={"使用您的Meris管理员账号"}
                    text_holder={"输入您的密码"}
                    filled_button={"登录"}
                    render_text_button={0}
                    isError={isError}
                    error_message={" 密码错误，请重试"}
                    loadAnimation={loadAnimation}
                    inputType1={'password'}
                ></LoginCard>
            )}
            <div className='helpful_links_container'>
                <button className='admin_login_page_button' onClick={userLoginPageButton}>用户登录</button>
                <p className='helpful_links'>帮助 隐私权 条款</p>
            </div>
        </div>
    )
}

export default AdminLogin