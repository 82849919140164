import LoginCard from '../../HelperComponent/LoginCard'
import '../../styles/login_page_styles/LoginDisplay.css'
import {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {admin_portal_page} from '../../Constants/api';
import Header from '../../HelperComponent/Portal/Header';
import React from 'react';
import Leftbar from "../../HelperComponent/Portal/Leftbar";


function AdminPortal() {
    const [authorized, setAuthorized] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthorization = async () => {
            const token = localStorage.getItem('jwtToken'); // Retrieve the token from local storage
            if (!token) {
                setAuthorized(false);
                return; // Early return if no token, sets authorized to false
            }

            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            try {
                // Attempt to make a POST request to the protected route
                const response = await axios.post(admin_portal_page, {}, config);
                if (response.status === 200) {
                    setAuthorized(true);
                }
            } catch (error) {
                setAuthorized(false);
                console.error('Error accessing protected route:', error);
            }
        };
        checkAuthorization();
    }, [navigate]);

    return (
        <div>
            {authorized ? (
                <div>
                    <div className={'page-container'}>
                        <Header></Header>
                        <Leftbar></Leftbar>
                    </div>
                </div>
            ) : (
                <div className='card-container'>
                    <LoginCard
                        title={"您无权访问该网页"}
                        sub_title={"如果您是Meris管理员，请尝试重新登录您的Meris管理员账号，然后重新访问此页。如果您是Meris用户，您无权访问此界面。如果您认为您有权限访问，或者持续看到次见面，请寻求Meris IT以寻求技术支持。"}
                        render_text_button={0}
                        render_text_box={false}
                        render_filled_button={false}
                    ></LoginCard>
                </div>
            )}
        </div>
    );
}


export default AdminPortal