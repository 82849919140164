import '../../styles/admin_portal/admin_home_page.css'
import InfoCard from "../../HelperComponent/Portal/InfoCard";
import React from 'react'

function AdminHomePage() {
    return (
        <div className={'container'}>
            <div className="welcome-text">
                <h2 style={{fontWeight: 'lighter'}}>欢迎使用Meris管理员面板</h2>
            </div>
            <div className={'search-box-container'}>
                <input className={'admin_home_search-box'} placeholder={"查找Meris员工或部门"}></input>
            </div>
            <div className={'admin_info_card_container'}>
                <InfoCard title={'待入职人数'} data={0}></InfoCard>
                <InfoCard title={'在职人数'} data={10}></InfoCard>
                <InfoCard title={'离职人数'} data={0}></InfoCard>
            </div>
        </div>
    )
}

export default AdminHomePage;