import '../../styles/admin_portal/left_bar_button.css'


function CreateUserButton({onClick, buttonText}) {
    return (
        <div>
        <button className={'left-bar-create-button'} onClick={onClick}>{buttonText}</button>
    </div>
    )
}

export default CreateUserButton;