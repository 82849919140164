import '../../styles/admin_portal/admin_portal_card.css'
import AdminHomePage from "../../component/admin-portal/AdminHomePage";
import AdminPendingPage from "../../component/admin-portal/AdminPendingPage"
import AdminCurrentEmployedPage from "../../component/admin-portal/AdminCurrentEmployedPage";
import AdminDismissedPage from "../../component/admin-portal/AdminDismissedPage";
import AdminNewUserPage from "../../component/admin-portal/AdminNewUserPage";
import '../../styles/admin_portal/loading_animation.css'
import {useState} from "react";

function CenterCard({current_page= 1}){
    const [loadingAnimation, setLoadingAnimation] = useState(false);

    return (
        <div className="center_card">
            {loadingAnimation === true && <div className={'portal_loading_line'}></div>}
            {current_page === 1 && <AdminHomePage></AdminHomePage>}
            {current_page === 2 && <AdminPendingPage></AdminPendingPage>}
            {current_page === 3 && <AdminCurrentEmployedPage></AdminCurrentEmployedPage>}
            {current_page === 4 && <AdminDismissedPage></AdminDismissedPage>}
            {current_page === 5 && <AdminNewUserPage setLoadingAnimation = {setLoadingAnimation}></AdminNewUserPage>}
        </div>
    );
}

export default CenterCard;