import '../../styles/admin_portal/header.css'
import Logo from '../../Assets/MerisLogoColor.png'

function Header() {
    return (
        <div className='header-class'>
            <img src={Logo} alt='Meris logo' className='logo'/>
        </div>
    )
}

export default Header;