import '../../styles/admin_portal/admin_create_user_page.css'
import CreateUserButton from "../../HelperComponent/Portal/CreateUserButton";
import '../../Constants/api'
import '../../styles/admin_portal/loading_animation.css'

import {useState} from "react";
import {admin_register_user} from "../../Constants/api";
import axios from "axios";

function AdminNewUserPage(props) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [year, setYear] = useState('')
    let [month, setMonth] = useState('1')
    let [day, setDay] = useState('')
    const [sex, setSex] = useState('')
    const [department, setDepartment] = useState('')
    const [email, setEmail] = useState('')
    const [step, setStep] = useState('register');

    const monthDropDownItem = [
        {value: '1', text: '1 月'},
        {value: '2', text: '2 月'},
        {value: '3', text: '3 月'},
        {value: '4', text: '4 月'},
        {value: '5', text: '5 月'},
        {value: '6', text: '6 月'},
        {value: '7', text: '7 月'},
        {value: '8', text: '8 月'},
        {value: '9', text: '9 月'},
        {value: '10', text: '10 月'},
        {value: '11', text: '11 月'},
        {value: '12', text: '12 月'}
    ];

    const sexDropDownItem = [
        {value: 'male', text: '男性'},
        {value: 'female', text: '女性'},
        {value: 'other', text: '其他'},
    ]

    const departmentDropDownItem = [
        {value: 'sales', text: 'Sales'},
        {value: 'engineering', text: 'Engineering'},
    ]

    const createUser = async (event) => {
        props.setLoadingAnimation(true);
        event.preventDefault();
        const apiUrl = admin_register_user;
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        const birthDay = `${year}-${month}-${day}`;
        console.log(birthDay);
        const requestData = {
            username: email,
            department: department,
            last_name: lastName,
            first_name: firstName,
            birthdate: birthDay,
            sex: sex
        }

        try {
            const response = await axios.post(apiUrl, requestData);
            if (response.data.msg === 'success'){
                setStep('success');
                props.setLoadingAnimation(false);
            }

        } catch (error) {
            console.log(error.response.data)
            props.setLoadingAnimation(false);
        }
    }


    return (
        <div>
            {step === 'register' && (
                <div>
                    {/*<div className={'portal_loading_line'}></div>*/}
                    <div className={'.container'}>
                        <div className={'personal-info-container'}>
                            <p>个人信息:</p>
                            <div className={'row'}>
                                <input className={'text-box-name'} placeholder={'姓氏'}
                                       onChange={(e) => setFirstName(e.target.value)}/>
                                <input className={'text-box-name'} placeholder={'名字'}
                                       onChange={(e) => setLastName(e.target.value)}/>
                            </div>


                            <p>出生日期:</p>
                            <div className={'row'}>
                                <input className={'text-box-birth'} placeholder={'年'}
                                       onChange={(e) => setYear(e.target.value)}/>

                                <select className={'text-box-birth'} value={month}
                                        onChange={(e) => setMonth(e.target.value)}>
                                    {monthDropDownItem.map((item, index) => (
                                        <option key={item.value} value={item.value}>
                                            {item.text}
                                        </option>
                                    ))}
                                </select>

                                <input className={'text-box-birth'} placeholder={'日'}
                                       onChange={(e) => setDay(e.target.value)}/>
                            </div>


                            <p>性别:</p>
                            <div className={'row'}>
                                <select className={'text-box-birth'} value={sex}
                                        onChange={(e) => setSex(e.target.value)}>
                                    {sexDropDownItem.map((item, index) => (
                                        <option key={item.value} value={item.value}>
                                            {item.text}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>

                        <div className={'horizontal-line'}></div>

                        <div className={'work-position-container'}>
                            <p>部门: </p>
                            <div className={'row'}>
                                <select className={'text-box-name'} value={department}
                                        onChange={(e) => setDepartment(e.target.value)}>
                                    {departmentDropDownItem.map((item, index) => (
                                        <option key={item.value} value={item.value}>
                                            {item.text}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <p>电子邮箱地址:</p>
                            <div className={'row'}>
                                <input className={'text-box-name'} placeholder={'电子邮箱地址'}
                                       onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                        </div>

                        <div className={'create-user-button-placement'}>
                            <CreateUserButton buttonText={'创建用户'} onClick={createUser}></CreateUserButton>
                        </div>
                    </div>
                </div>
            )}

            {step === 'success' && (
                <div className={'container'}>
                    <h1>Success</h1>
                </div>
            )}
        </div>
    )
}

export default AdminNewUserPage;