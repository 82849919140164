import '../../styles/admin_portal/left_bar_button.css'

function LeftBarButtons({button_name, onClick, selected}) {
    const buttonColorClass = selected ? 'left-bar-buttons-selected' : 'left-bar-buttons';

    return (
        <div>
            <button className={buttonColorClass} onClick={onClick}>{button_name}</button>
        </div>

    )
}

export default LeftBarButtons;