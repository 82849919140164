import React from 'react'
import '../styles/login_page_styles/Login_Buttons.css'
import '../styles/Card.css'
import '../styles/login_page_styles/Login_Search.css'
import Logo from '../Assets/MerisLogoColor.png'
import '../styles/login_page_styles/Login_Logo.css'
import {GoAlertFill} from "react-icons/go";
import '../styles/login_page_styles/LineAnimation.css'

function LoginCard({
                       textInput,
                       setTextInput,
                       text_button_click,
                       filled_button_click,
                       title,
                       sub_title,
                       text_holder,
                       text_button,
                       filled_button,
                       render_text_button = 1,
                       isError,
                       error_message,
                       loadAnimation,
                       render_text_box = true,
                       render_filled_button = true,
                       render_second_textInput = false,
                       text_holder2,
                       text_input2,
                       setTextInput2,
                       inputType1 = 'text',
                       inputType2 = 'text',
                   }) {
    const inputClass = `search_box ${isError ? 'input-error' : ''}`;
    const inputClass2 = `search_box2 ${isError ? 'input-error' : ''}`;
    const botton_container = 'botton-container'
    return (
        <div className="card">
            {/* <div className='line'></div> */}
            {loadAnimation === true && <div className='line'></div>}
            {loadAnimation === true && <div className='overlay'></div>}
            <div className="card-left">
                <img src={Logo} alt='Meris logo' className='Meris-Logo'/>
                <div className='text-left'>
                    <div>
                        <p className='login-text' style={{marginBottom: '2px'}}>{title}</p>
                        <p style={{marginLeft: '2px', marginTop: '20px'}}>{sub_title}</p>
                    </div>

                </div>
            </div>
            <div className="card-right">
                <div>
                    {render_text_box === true && <input className={inputClass}
                                                        type={inputType1}
                                                        value={textInput}
                                                        onChange={setTextInput}
                                                        placeholder={text_holder}
                                                        style={{fontSize: '18px'}}
                    />}
                    {render_second_textInput === true && <input className={inputClass2}
                                                                type={inputType2}
                                                                value={text_input2}
                                                                onChange={setTextInput2}
                                                                placeholder={text_holder2}
                                                                style={{fontSize: '18px'}}
                    />}
                    {isError === true &&
                        <p style={{color: '#b3261e', marginTop: '0px', marginLeft: '10px'}}><GoAlertFill
                            style={{verticalAlign: '-2px'}}/>{error_message}</p>}
                    <div className={botton_container}>
                        {render_text_button === 1 &&
                            <button className='blue_text_button' onClick={text_button_click}>{text_button}</button>}
                        {render_filled_button === true && <button className='blue_filled_button'
                                                                  onClick={filled_button_click}>{filled_button}</button>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginCard;
