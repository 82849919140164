import React from "react";
import '../../styles/admin_portal/left_bar.css'
import LeftBarButtons from "./LeftBarButtons";
import '../../styles/admin_portal/portal_style.css'
import CreateUserButton from "./CreateUserButton";
import {useState} from "react";
import CenterCard from "./CenterCard";
import RightCard from "./RightCard";

function Leftbar() {
    const [homeSelected, setHomeSelected] = useState(true);
    const [pendingSelected, setPendingSelected] = useState(false);
    const [currentUserSelected, setCurrentUserSelected] = useState(false);
    const [removedUserSelected, setRemovedUserSelected] = useState(false);
    /**
     * Note that current page is 1-5: 1: homepage, 2: pending page, 3: current user, 4: removed user, 5: create user
     */
    const [currentPage, setCurrentPage] = useState(1);


    const homeOnClick = (events) => {
        setHomeSelected(true)
        setPendingSelected(false);
        setCurrentUserSelected(false);
        setRemovedUserSelected(false);
        setCurrentPage(1);
    }
    const pendingOnClick = (events) => {
        setHomeSelected(false)
        setPendingSelected(true);
        setCurrentUserSelected(false);
        setRemovedUserSelected(false);
        setCurrentPage(2);
    }
    const currentUserOnClick = (events) => {
        setHomeSelected(false)
        setPendingSelected(false);
        setCurrentUserSelected(true);
        setRemovedUserSelected(false);
        setCurrentPage(3);
    }
    const removedUserOnClick = (events) => {
        setHomeSelected(false)
        setPendingSelected(false);
        setCurrentUserSelected(false);
        setRemovedUserSelected(true);
        setCurrentPage(4);
    }

    const createUserOnclick = (events) =>{
        setHomeSelected(false)
        setPendingSelected(false);
        setCurrentUserSelected(false);
        setRemovedUserSelected(false);
        setCurrentPage(5);
    }


    return (
        <div className={'center-card-container'}>
            <div className="left-bar">
                <CreateUserButton onClick={createUserOnclick} buttonText={' + 新用户'}/>
                <div>
                    <LeftBarButtons button_name={"主页"} onClick={homeOnClick} selected={homeSelected}></LeftBarButtons>
                    <LeftBarButtons button_name={"待入职"} onClick={pendingOnClick}
                                    selected={pendingSelected}></LeftBarButtons>
                    <LeftBarButtons button_name={"在岗员工"} onClick={currentUserOnClick}
                                    selected={currentUserSelected}></LeftBarButtons>
                    <LeftBarButtons button_name={"离职员工"} onClick={removedUserOnClick}
                                    selected={removedUserSelected}></LeftBarButtons>
                </div>
            </div>
            <CenterCard current_page={currentPage}></CenterCard>
            <RightCard></RightCard>
        </div>

    )
}

export default Leftbar;