import '../../styles/admin_portal/admin_home_page.css'

function InfoCard({title, data}) {
    return(
        <div className="admin_info_card">
            <h1>{title}</h1>
            <p>{data}</p>
        </div>
    )
}

export default InfoCard;