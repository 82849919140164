const absolute_path = 'https://meris-426213.uc.r.appspot.com';


export const admin_user_name_lookup = absolute_path + '/v1/admin/username_lookup';
export const admin_user_password_check = absolute_path + '/v1/admin/password_check';
export const admin_portal_page = absolute_path + '/v1/admin/portal';
export const admin_register_user = absolute_path + '/v1/admin/register_user';
export const admin_list_pending_user = absolute_path + '/v1/admin/list_pending_user';
export const user_check_regkey = absolute_path + '/v1/user/check_regkey';
export const user_register = absolute_path + '/v1/user/register';
export const user_username_lookup = absolute_path + '/v1/user/username_lookup';
export const user_login = absolute_path + '/v1/user/login';
export const user_home = absolute_path + '/v1/user/home';