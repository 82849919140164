import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LoginDisplay from "./component/login-signup-display/LoginDisplay";
import './styles/login_page_styles/LoginDisplay.css';
import SignupDisplay from './component/login-signup-display/SignupDisplay';
import AdminLogin from './component/login-signup-display/AdminLogin'
import AdminPortal from './component/admin-portal/AdminPortal'
import UserHome from "./component/user/UserHome";


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginDisplay/>}/>
                <Route path="/signup" element={<SignupDisplay></SignupDisplay>}></Route>
                <Route path='/admin/login' element={<AdminLogin></AdminLogin>}></Route>
                <Route path='/admin/portal' element={<AdminPortal></AdminPortal>}></Route>
                <Route path='/user/home' element={<UserHome></UserHome>}></Route>
            </Routes>
        </Router>
    );
}

export default App;
